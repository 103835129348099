import React from 'react';
import { Spinner } from './spinner';
import cn from 'classnames';

export const Card: React.FC<{ className?: string; loading?: boolean }> = ({
    children,
    loading = false,
    className,
    ...props
}) => (
    <div
        className={cn(
            'relative overflow-hidden p-4 md:p-16 md:my-8 mx-auto max-w-2xl bg-magic-silver md:shadow-magic md:rounded-xl space-y-8 w-full',
            className
        )}
        {...props}
    >
        {children}
        {loading ? (
            <div className="absolute -top-8 left-0 right-0 bottom-0 bg-magic-silver/80 flex items-center justify-center">
                <Spinner visible />
            </div>
        ) : null}
    </div>
);
