import { useRouter } from 'next/dist/client/router';
import { useEffect } from 'react';
import { Card } from '../src/components/card';
import { Loading } from '../src/components/loading';
import { useUser } from '../src/hooks/user/user';

const HomePage = () => {
    const router = useRouter();
    const { user, loading } = useUser();

    useEffect(() => {
        // If we don't have a user and is not loading
        // means the user is logged out
        if (!user && !loading) {
            router.push('/login');
        } else if (user && user.privileges?.admin) {
            router.push('/admin'); // Immediately redirect to admin if logged in as admin
        }
    }, [router, user, loading]);

    if (!user) {
        return (
            <div className="flex flex-col p-4 my-4">
                <Card className="max-w-2xl" loading>
                    <Loading />
                </Card>
            </div>
        );
    }

    return (
        <div className="flex flex-col p-4 my-4">
            <Card className="container" loading={loading}>
                <p className="text-2xl font-bold text-magic-purple">
                    Welcome, {user['custom:first_name']}!
                </p>
                <p className="text-lg">This page is under construction.</p>
            </Card>
        </div>
    );
};

export default HomePage;
